@font-face {
    font-family: 'Open Sans';
    src: url('presentation/theme/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('presentation/theme/fonts/OpenSans/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('presentation/theme/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('presentation/theme/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans';
    src: url('presentation/theme/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
    url('presentation/theme/fonts/OpenSans/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

html {
    box-sizing: border-box;
    font-size: 16px;
}

@media (max-width: 960px) {
    html {
        font-size: 15px;
    }
}

@media (max-width: 640px) {
    html {
        font-size: 15px;
    }
}

@media (max-width: 320px) {
    html {
        font-size: 14px;
    }
}

*, *:before, *:after {
    box-sizing: inherit;
}

h1, h2, h3 {
    font-weight: bold;
}

h1, h2, h3, p {
    margin: 0;
    padding: 0;
}

img, button {
    vertical-align: top;
}

body {
    margin: 0;
    padding: 0;

    font-family: 'Open Sans', sans-serif;
    line-height: 1.5;
    font-weight: normal;
    color: #443835;

    background-color: #fff;
}
